import _StripeResource from "../../../StripeResource";
var exports = {};
const StripeResource = _StripeResource;
const stripeMethod = StripeResource.method;
exports = StripeResource.extend({
  path: "test_helpers/treasury/received_debits",
  create: stripeMethod({
    method: "POST",
    path: ""
  })
});
export default exports;