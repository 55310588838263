import _StripeResource from "../StripeResource";
var exports = {};
const StripeResource = _StripeResource;
const stripeMethod = StripeResource.method;
exports = StripeResource.extend({
  path: "balance",
  retrieve: stripeMethod({
    method: "GET",
    path: ""
  })
});
export default exports;