import _StripeResource from "../../StripeResource";
var exports = {};
const StripeResource = _StripeResource;
const stripeMethod = StripeResource.method;
exports = StripeResource.extend({
  path: "apps/secrets",
  create: stripeMethod({
    method: "POST",
    path: ""
  }),
  list: stripeMethod({
    method: "GET",
    path: "",
    methodType: "list"
  }),
  deleteWhere: stripeMethod({
    method: "POST",
    path: "/delete"
  }),
  find: stripeMethod({
    method: "GET",
    path: "/find"
  })
});
export default exports;