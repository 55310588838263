import _StripeResource from "../StripeResource";
var exports = {};
const StripeResource = _StripeResource;
const stripeMethod = StripeResource.method;
exports = StripeResource.extend({
  path: "transfers",
  create: stripeMethod({
    method: "POST",
    path: ""
  }),
  retrieve: stripeMethod({
    method: "GET",
    path: "/{transfer}"
  }),
  update: stripeMethod({
    method: "POST",
    path: "/{transfer}"
  }),
  list: stripeMethod({
    method: "GET",
    path: "",
    methodType: "list"
  }),
  createReversal: stripeMethod({
    method: "POST",
    path: "/{id}/reversals"
  }),
  retrieveReversal: stripeMethod({
    method: "GET",
    path: "/{transfer}/reversals/{id}"
  }),
  updateReversal: stripeMethod({
    method: "POST",
    path: "/{transfer}/reversals/{id}"
  }),
  listReversals: stripeMethod({
    method: "GET",
    path: "/{id}/reversals",
    methodType: "list"
  })
});
export default exports;