import _StripeResource from "../StripeResource";
var exports = {};
const StripeResource = _StripeResource;
const stripeMethod = StripeResource.method;
exports = StripeResource.extend({
  path: "account_links",
  create: stripeMethod({
    method: "POST",
    path: ""
  })
});
export default exports;