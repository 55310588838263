import _StripeResource from "../../../StripeResource";
var exports = {};
const StripeResource = _StripeResource;
const stripeMethod = StripeResource.method;
exports = StripeResource.extend({
  path: "test_helpers/treasury/outbound_payments",
  fail: stripeMethod({
    method: "POST",
    path: "/{id}/fail"
  }),
  post: stripeMethod({
    method: "POST",
    path: "/{id}/post"
  }),
  returnOutboundPayment: stripeMethod({
    method: "POST",
    path: "/{id}/return"
  })
});
export default exports;