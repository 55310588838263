import * as _crypto2 from "crypto";

var _crypto = "default" in _crypto2 ? _crypto2.default : _crypto2;

import _CryptoProvider from "./CryptoProvider";
var exports = {};
const crypto = _crypto;
const CryptoProvider = _CryptoProvider;
/**
 * `CryptoProvider which uses the Node `crypto` package for its computations.
 */

class NodeCryptoProvider extends CryptoProvider {
  /** @override */
  computeHMACSignature(payload, secret) {
    return crypto.createHmac("sha256", secret).update(payload, "utf8").digest("hex");
  }
  /** @override */


  async computeHMACSignatureAsync(payload, secret) {
    const signature = await this.computeHMACSignature(payload, secret);
    return signature;
  }

}

exports = NodeCryptoProvider;
export default exports;