import _ResourceNamespace from "./ResourceNamespace";
import _Accounts from "./resources/Accounts";
import _AccountLinks from "./resources/AccountLinks";
import _ApplePayDomains from "./resources/ApplePayDomains";
import _ApplicationFees from "./resources/ApplicationFees";
import _Balance from "./resources/Balance";
import _BalanceTransactions from "./resources/BalanceTransactions";
import _Charges from "./resources/Charges";
import _CountrySpecs from "./resources/CountrySpecs";
import _Coupons from "./resources/Coupons";
import _CreditNotes from "./resources/CreditNotes";
import _Customers from "./resources/Customers";
import _Disputes from "./resources/Disputes";
import _EphemeralKeys from "./resources/EphemeralKeys";
import _Events from "./resources/Events";
import _ExchangeRates from "./resources/ExchangeRates";
import _Files from "./resources/Files";
import _FileLinks from "./resources/FileLinks";
import _Invoices from "./resources/Invoices";
import _InvoiceItems from "./resources/InvoiceItems";
import _Mandates from "./resources/Mandates";
import _OAuth from "./resources/OAuth";
import _Orders from "./resources/Orders";
import _PaymentIntents from "./resources/PaymentIntents";
import _PaymentLinks from "./resources/PaymentLinks";
import _PaymentMethods from "./resources/PaymentMethods";
import _Payouts from "./resources/Payouts";
import _Plans from "./resources/Plans";
import _Prices from "./resources/Prices";
import _Products from "./resources/Products";
import _PromotionCodes from "./resources/PromotionCodes";
import _Quotes from "./resources/Quotes";
import _Refunds from "./resources/Refunds";
import _Reviews from "./resources/Reviews";
import _SetupAttempts from "./resources/SetupAttempts";
import _SetupIntents from "./resources/SetupIntents";
import _ShippingRates from "./resources/ShippingRates";
import _SKUs from "./resources/SKUs";
import _Sources from "./resources/Sources";
import _Subscriptions from "./resources/Subscriptions";
import _SubscriptionItems from "./resources/SubscriptionItems";
import _SubscriptionSchedules from "./resources/SubscriptionSchedules";
import _TaxCodes from "./resources/TaxCodes";
import _TaxRates from "./resources/TaxRates";
import _Tokens from "./resources/Tokens";
import _Topups from "./resources/Topups";
import _Transfers from "./resources/Transfers";
import _WebhookEndpoints from "./resources/WebhookEndpoints";
import _Secrets from "./resources/Apps/Secrets";
import _Configurations from "./resources/BillingPortal/Configurations";
import _Sessions from "./resources/BillingPortal/Sessions";
import _Sessions2 from "./resources/Checkout/Sessions";
import _Accounts2 from "./resources/FinancialConnections/Accounts";
import _Sessions3 from "./resources/FinancialConnections/Sessions";
import _VerificationReports from "./resources/Identity/VerificationReports";
import _VerificationSessions from "./resources/Identity/VerificationSessions";
import _Authorizations from "./resources/Issuing/Authorizations";
import _Cards from "./resources/Issuing/Cards";
import _Cardholders from "./resources/Issuing/Cardholders";
import _Disputes2 from "./resources/Issuing/Disputes";
import _Transactions from "./resources/Issuing/Transactions";
import _EarlyFraudWarnings from "./resources/Radar/EarlyFraudWarnings";
import _ValueLists from "./resources/Radar/ValueLists";
import _ValueListItems from "./resources/Radar/ValueListItems";
import _ReportRuns from "./resources/Reporting/ReportRuns";
import _ReportTypes from "./resources/Reporting/ReportTypes";
import _ScheduledQueryRuns from "./resources/Sigma/ScheduledQueryRuns";
import _Configurations2 from "./resources/Terminal/Configurations";
import _ConnectionTokens from "./resources/Terminal/ConnectionTokens";
import _Locations from "./resources/Terminal/Locations";
import _Readers from "./resources/Terminal/Readers";
import _Customers2 from "./resources/TestHelpers/Customers";
import _Refunds2 from "./resources/TestHelpers/Refunds";
import _TestClocks from "./resources/TestHelpers/TestClocks";
import _Cards2 from "./resources/TestHelpers/Issuing/Cards";
import _Readers2 from "./resources/TestHelpers/Terminal/Readers";
import _InboundTransfers from "./resources/TestHelpers/Treasury/InboundTransfers";
import _OutboundPayments from "./resources/TestHelpers/Treasury/OutboundPayments";
import _OutboundTransfers from "./resources/TestHelpers/Treasury/OutboundTransfers";
import _ReceivedCredits from "./resources/TestHelpers/Treasury/ReceivedCredits";
import _ReceivedDebits from "./resources/TestHelpers/Treasury/ReceivedDebits";
import _CreditReversals from "./resources/Treasury/CreditReversals";
import _DebitReversals from "./resources/Treasury/DebitReversals";
import _FinancialAccounts from "./resources/Treasury/FinancialAccounts";
import _InboundTransfers2 from "./resources/Treasury/InboundTransfers";
import _OutboundPayments2 from "./resources/Treasury/OutboundPayments";
import _OutboundTransfers2 from "./resources/Treasury/OutboundTransfers";
import _ReceivedCredits2 from "./resources/Treasury/ReceivedCredits";
import _ReceivedDebits2 from "./resources/Treasury/ReceivedDebits";
import _Transactions2 from "./resources/Treasury/Transactions";
import _TransactionEntries from "./resources/Treasury/TransactionEntries";
var exports = {};
const resourceNamespace = _ResourceNamespace;
exports = {
  Accounts: _Accounts,
  // Support Accounts for consistency, Account for backwards compatibility
  Account: _Accounts,
  AccountLinks: _AccountLinks,
  ApplePayDomains: _ApplePayDomains,
  ApplicationFees: _ApplicationFees,
  Balance: _Balance,
  BalanceTransactions: _BalanceTransactions,
  Charges: _Charges,
  CountrySpecs: _CountrySpecs,
  Coupons: _Coupons,
  CreditNotes: _CreditNotes,
  Customers: _Customers,
  Disputes: _Disputes,
  EphemeralKeys: _EphemeralKeys,
  Events: _Events,
  ExchangeRates: _ExchangeRates,
  Files: _Files,
  FileLinks: _FileLinks,
  Invoices: _Invoices,
  InvoiceItems: _InvoiceItems,
  Mandates: _Mandates,
  OAuth: _OAuth,
  Orders: _Orders,
  PaymentIntents: _PaymentIntents,
  PaymentLinks: _PaymentLinks,
  PaymentMethods: _PaymentMethods,
  Payouts: _Payouts,
  Plans: _Plans,
  Prices: _Prices,
  Products: _Products,
  PromotionCodes: _PromotionCodes,
  Quotes: _Quotes,
  Refunds: _Refunds,
  Reviews: _Reviews,
  SetupAttempts: _SetupAttempts,
  SetupIntents: _SetupIntents,
  ShippingRates: _ShippingRates,
  Skus: _SKUs,
  Sources: _Sources,
  Subscriptions: _Subscriptions,
  SubscriptionItems: _SubscriptionItems,
  SubscriptionSchedules: _SubscriptionSchedules,
  TaxCodes: _TaxCodes,
  TaxRates: _TaxRates,
  Tokens: _Tokens,
  Topups: _Topups,
  Transfers: _Transfers,
  WebhookEndpoints: _WebhookEndpoints,
  Apps: resourceNamespace("apps", {
    Secrets: _Secrets
  }),
  BillingPortal: resourceNamespace("billingPortal", {
    Configurations: _Configurations,
    Sessions: _Sessions
  }),
  Checkout: resourceNamespace("checkout", {
    Sessions: _Sessions2
  }),
  FinancialConnections: resourceNamespace("financialConnections", {
    Accounts: _Accounts2,
    Sessions: _Sessions3
  }),
  Identity: resourceNamespace("identity", {
    VerificationReports: _VerificationReports,
    VerificationSessions: _VerificationSessions
  }),
  Issuing: resourceNamespace("issuing", {
    Authorizations: _Authorizations,
    Cards: _Cards,
    Cardholders: _Cardholders,
    Disputes: _Disputes2,
    Transactions: _Transactions
  }),
  Radar: resourceNamespace("radar", {
    EarlyFraudWarnings: _EarlyFraudWarnings,
    ValueLists: _ValueLists,
    ValueListItems: _ValueListItems
  }),
  Reporting: resourceNamespace("reporting", {
    ReportRuns: _ReportRuns,
    ReportTypes: _ReportTypes
  }),
  Sigma: resourceNamespace("sigma", {
    ScheduledQueryRuns: _ScheduledQueryRuns
  }),
  Terminal: resourceNamespace("terminal", {
    Configurations: _Configurations2,
    ConnectionTokens: _ConnectionTokens,
    Locations: _Locations,
    Readers: _Readers
  }),
  TestHelpers: resourceNamespace("testHelpers", {
    Customers: _Customers2,
    Refunds: _Refunds2,
    TestClocks: _TestClocks,
    Issuing: resourceNamespace("issuing", {
      Cards: _Cards2
    }),
    Terminal: resourceNamespace("terminal", {
      Readers: _Readers2
    }),
    Treasury: resourceNamespace("treasury", {
      InboundTransfers: _InboundTransfers,
      OutboundPayments: _OutboundPayments,
      OutboundTransfers: _OutboundTransfers,
      ReceivedCredits: _ReceivedCredits,
      ReceivedDebits: _ReceivedDebits
    })
  }),
  Treasury: resourceNamespace("treasury", {
    CreditReversals: _CreditReversals,
    DebitReversals: _DebitReversals,
    FinancialAccounts: _FinancialAccounts,
    InboundTransfers: _InboundTransfers2,
    OutboundPayments: _OutboundPayments2,
    OutboundTransfers: _OutboundTransfers2,
    ReceivedCredits: _ReceivedCredits2,
    ReceivedDebits: _ReceivedDebits2,
    Transactions: _Transactions2,
    TransactionEntries: _TransactionEntries
  })
};
export default exports;