export default {
  "name": "stripe",
  "version": "10.5.0",
  "description": "Stripe API wrapper",
  "keywords": [
    "stripe",
    "payment processing",
    "credit cards",
    "api"
  ],
  "homepage": "https://github.com/stripe/stripe-node",
  "author": "Stripe <support@stripe.com> (https://stripe.com/)",
  "contributors": [
    "Ask Bjørn Hansen <ask@develooper.com> (http://www.askask.com/)",
    "Michelle Bu <michelle@stripe.com>",
    "Alex Sexton <alex@stripe.com>",
    "James Padolsey"
  ],
  "repository": {
    "type": "git",
    "url": "git://github.com/stripe/stripe-node.git"
  },
  "bugs": "https://github.com/stripe/stripe-node/issues",
  "engines": {
    "node": "^8.1 || >=10.*"
  },
  "main": "lib/stripe.js",
  "types": "types/2022-08-01/index.d.ts",
  "devDependencies": {
    "@typescript-eslint/eslint-plugin": "^2.13.0",
    "@typescript-eslint/parser": "^2.13.0",
    "chai": "~4.2.0",
    "chai-as-promised": "~7.1.1",
    "coveralls": "^3.1.1",
    "eslint": "^6.8.0",
    "eslint-config-prettier": "^4.1.0",
    "eslint-plugin-chai-friendly": "^0.4.0",
    "eslint-plugin-prettier": "^3.0.1",
    "mocha": "^8.3.2",
    "mocha-junit-reporter": "^1.23.1",
    "nock": "^13.1.1",
    "node-fetch": "^2.6.2",
    "nyc": "^15.1.0",
    "prettier": "^1.16.4",
    "typescript": "^3.7.2"
  },
  "resolutions": {
    "ansi-regex": "5.0.1",
    "minimist": "1.2.6",
    "nanoid": "3.2.0"
  },
  "dependencies": {
    "@types/node": ">=8.1.0",
    "qs": "^6.10.3"
  },
  "license": "MIT",
  "scripts": {
    "clean": "rm -rf ./.nyc_output ./node_modules/.cache ./coverage",
    "mocha": "nyc mocha --config=test/.mocharc.js",
    "mocha-only": "mocha --config=test/.mocharc.js",
    "test": "yarn test-typescript && yarn mocha",
    "test-typescript": "tsc --build types/test",
    "lint": "eslint --ext .js,.jsx,.ts .",
    "fix": "yarn lint --fix && ./scripts/updateAPIVersion.js",
    "report": "nyc -r text -r lcov report",
    "coveralls": "cat coverage/lcov.info | ./node_modules/coveralls/bin/coveralls.js"
  }
}
;