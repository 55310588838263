import _StripeResource from "../StripeResource";
var exports = {};
const StripeResource = _StripeResource;
const stripeMethod = StripeResource.method;
exports = StripeResource.extend({
  path: "payment_intents",
  create: stripeMethod({
    method: "POST",
    path: ""
  }),
  retrieve: stripeMethod({
    method: "GET",
    path: "/{intent}"
  }),
  update: stripeMethod({
    method: "POST",
    path: "/{intent}"
  }),
  list: stripeMethod({
    method: "GET",
    path: "",
    methodType: "list"
  }),
  applyCustomerBalance: stripeMethod({
    method: "POST",
    path: "/{intent}/apply_customer_balance"
  }),
  cancel: stripeMethod({
    method: "POST",
    path: "/{intent}/cancel"
  }),
  capture: stripeMethod({
    method: "POST",
    path: "/{intent}/capture"
  }),
  confirm: stripeMethod({
    method: "POST",
    path: "/{intent}/confirm"
  }),
  incrementAuthorization: stripeMethod({
    method: "POST",
    path: "/{intent}/increment_authorization"
  }),
  search: stripeMethod({
    method: "GET",
    path: "/search",
    methodType: "search"
  }),
  verifyMicrodeposits: stripeMethod({
    method: "POST",
    path: "/{intent}/verify_microdeposits"
  })
});
export default exports;