import _StripeResource from "../../StripeResource";
var exports = {};
const StripeResource = _StripeResource;
const stripeMethod = StripeResource.method;
exports = StripeResource.extend({
  path: "sigma/scheduled_query_runs",
  retrieve: stripeMethod({
    method: "GET",
    path: "/{scheduledQueryRun}"
  }),
  list: stripeMethod({
    method: "GET",
    path: "",
    methodType: "list"
  })
});
export default exports;