import _StripeResource from "../StripeResource";
var exports = {};
const StripeResource = _StripeResource;
const stripeMethod = StripeResource.method;
exports = StripeResource.extend({
  path: "exchange_rates",
  retrieve: stripeMethod({
    method: "GET",
    path: "/{rateId}"
  }),
  list: stripeMethod({
    method: "GET",
    path: "",
    methodType: "list"
  })
});
export default exports;