import _StripeResource from "../StripeResource";
var exports = {};
const StripeResource = _StripeResource;
const stripeMethod = StripeResource.method;
exports = StripeResource.extend({
  path: "setup_intents",
  create: stripeMethod({
    method: "POST",
    path: ""
  }),
  retrieve: stripeMethod({
    method: "GET",
    path: "/{intent}"
  }),
  update: stripeMethod({
    method: "POST",
    path: "/{intent}"
  }),
  list: stripeMethod({
    method: "GET",
    path: "",
    methodType: "list"
  }),
  cancel: stripeMethod({
    method: "POST",
    path: "/{intent}/cancel"
  }),
  confirm: stripeMethod({
    method: "POST",
    path: "/{intent}/confirm"
  }),
  verifyMicrodeposits: stripeMethod({
    method: "POST",
    path: "/{intent}/verify_microdeposits"
  })
});
export default exports;