import _StripeResource from "../../StripeResource";
var exports = {};
const StripeResource = _StripeResource;
const stripeMethod = StripeResource.method;
exports = StripeResource.extend({
  path: "radar/value_list_items",
  create: stripeMethod({
    method: "POST",
    path: ""
  }),
  retrieve: stripeMethod({
    method: "GET",
    path: "/{item}"
  }),
  list: stripeMethod({
    method: "GET",
    path: "",
    methodType: "list"
  }),
  del: stripeMethod({
    method: "DELETE",
    path: "/{item}"
  })
});
export default exports;