import _StripeResource from "../../StripeResource";
var exports = {};
const StripeResource = _StripeResource;
const stripeMethod = StripeResource.method;
exports = StripeResource.extend({
  path: "financial_connections/accounts",
  retrieve: stripeMethod({
    method: "GET",
    path: "/{account}"
  }),
  list: stripeMethod({
    method: "GET",
    path: "",
    methodType: "list"
  }),
  disconnect: stripeMethod({
    method: "POST",
    path: "/{account}/disconnect"
  }),
  listOwners: stripeMethod({
    method: "GET",
    path: "/{account}/owners",
    methodType: "list"
  }),
  refresh: stripeMethod({
    method: "POST",
    path: "/{account}/refresh"
  })
});
export default exports;