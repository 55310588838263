import _StripeResource from "../../StripeResource";
var exports = {};
const StripeResource = _StripeResource;
const stripeMethod = StripeResource.method;
exports = StripeResource.extend({
  path: "test_helpers/refunds",
  expire: stripeMethod({
    method: "POST",
    path: "/{refund}/expire"
  })
});
export default exports;