import _StripeResource from "../../StripeResource";
var exports = {};
const StripeResource = _StripeResource;
const stripeMethod = StripeResource.method;
exports = StripeResource.extend({
  path: "test_helpers/customers",
  fundCashBalance: stripeMethod({
    method: "POST",
    path: "/{customer}/fund_cash_balance"
  })
});
export default exports;