import _StripeResource from "../StripeResource";
var exports = {};
const StripeResource = _StripeResource;
const stripeMethod = StripeResource.method;
exports = StripeResource.extend({
  path: "subscription_schedules",
  create: stripeMethod({
    method: "POST",
    path: ""
  }),
  retrieve: stripeMethod({
    method: "GET",
    path: "/{schedule}"
  }),
  update: stripeMethod({
    method: "POST",
    path: "/{schedule}"
  }),
  list: stripeMethod({
    method: "GET",
    path: "",
    methodType: "list"
  }),
  cancel: stripeMethod({
    method: "POST",
    path: "/{schedule}/cancel"
  }),
  release: stripeMethod({
    method: "POST",
    path: "/{schedule}/release"
  })
});
export default exports;