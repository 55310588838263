import _StripeResource from "../../../StripeResource";
var exports = {};
const StripeResource = _StripeResource;
const stripeMethod = StripeResource.method;
exports = StripeResource.extend({
  path: "test_helpers/terminal/readers",
  presentPaymentMethod: stripeMethod({
    method: "POST",
    path: "/{reader}/present_payment_method"
  })
});
export default exports;