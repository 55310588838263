import _StripeResource from "../StripeResource";
var exports = {};
const StripeResource = _StripeResource;
const stripeMethod = StripeResource.method;
exports = StripeResource.extend({
  path: "credit_notes",
  create: stripeMethod({
    method: "POST",
    path: ""
  }),
  retrieve: stripeMethod({
    method: "GET",
    path: "/{id}"
  }),
  update: stripeMethod({
    method: "POST",
    path: "/{id}"
  }),
  list: stripeMethod({
    method: "GET",
    path: "",
    methodType: "list"
  }),
  listPreviewLineItems: stripeMethod({
    method: "GET",
    path: "/preview/lines",
    methodType: "list"
  }),
  preview: stripeMethod({
    method: "GET",
    path: "/preview"
  }),
  voidCreditNote: stripeMethod({
    method: "POST",
    path: "/{id}/void"
  }),
  listLineItems: stripeMethod({
    method: "GET",
    path: "/{creditNote}/lines",
    methodType: "list"
  })
});
export default exports;