import _StripeResource from "../../../StripeResource";
var exports = {};
const StripeResource = _StripeResource;
const stripeMethod = StripeResource.method;
exports = StripeResource.extend({
  path: "test_helpers/issuing/cards",
  deliverCard: stripeMethod({
    method: "POST",
    path: "/{card}/shipping/deliver"
  }),
  failCard: stripeMethod({
    method: "POST",
    path: "/{card}/shipping/fail"
  }),
  returnCard: stripeMethod({
    method: "POST",
    path: "/{card}/shipping/return"
  }),
  shipCard: stripeMethod({
    method: "POST",
    path: "/{card}/shipping/ship"
  })
});
export default exports;