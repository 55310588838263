import _StripeResource from "../../StripeResource";
var exports = {};
const StripeResource = _StripeResource;
const stripeMethod = StripeResource.method;
exports = StripeResource.extend({
  path: "billing_portal/sessions",
  create: stripeMethod({
    method: "POST",
    path: ""
  })
});
export default exports;