import _StripeResource from "../../../StripeResource";
var exports = {};
const StripeResource = _StripeResource;
const stripeMethod = StripeResource.method;
exports = StripeResource.extend({
  path: "test_helpers/treasury/inbound_transfers",
  fail: stripeMethod({
    method: "POST",
    path: "/{id}/fail"
  }),
  returnInboundTransfer: stripeMethod({
    method: "POST",
    path: "/{id}/return"
  }),
  succeed: stripeMethod({
    method: "POST",
    path: "/{id}/succeed"
  })
});
export default exports;